// index.js
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/core';
import theme from './util/theme';

import store from './redux/store';
import App from './App';



const persistor = persistStore(store);
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <Router>
                <App />
            </Router>
          </React.StrictMode>
        </PersistGate>
      </ChakraProvider>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <Router>
                <App />
            </Router>
          </React.StrictMode>
        </PersistGate>
      </ChakraProvider>
    </Provider>,
    rootElement
  );
}