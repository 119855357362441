import React from 'react';
import { Box, Heading } from '@chakra-ui/core';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Heading size="xl">Recensioni </Heading>
    </Box>
  );
}
